$mobile-breakpoint: 780px;
$tablet-breakpoint: 1000px;
$large-desktop-breakpoint: 1367px;

@mixin for-size($point) {
    @if $point == mobile-only {
        @media (max-width: $mobile-breakpoint) { @content; }
    }
    @else if $point == tablet-only {
        @media (max-width: $tablet-breakpoint) { @content; }
    }
    @else if $point == large-desktop {
        @media (min-width: $large-desktop-breakpoint)  { @content; }
    }
}

@mixin for-custom($cutsom-bp) {
    @media (max-width: $cutsom-bp) { @content; }
}