@import '../../common.scss';

.no-internet-popup-box{
    background: #e4e7ea;
}

.no-internet-popup{
    text-align: center;
    figure {
        max-width: 100px;
        margin: 0 auto 40px;
    }
    h4 {
        font-size: 17px;
    }
    p {
        font-size: 16px;
        max-width: 180px;
        margin: 10px auto 30px;
        color: $medium-gray;
        font-size: rem(13);
    }
    a{
        padding: 10px 25px;
        border: 0;
        background-color: $primary-color;
        border-radius: 5px;
        color: $white;
        cursor: pointer;
        @include transition(0.3s ease all);
        text-decoration: none;
        display: block;
        max-width: 120px;
        margin: 0 auto;
        &:hover{
            @include box-shadow(0px 0px 10px rgba(0,0,0,.4));
        }
    }
    button{
        background-color: $primary-color;
        padding: 10px 20px;
        border: 0px;
        border-radius: 5px;
        color: $white;
        cursor: pointer;
    }
}
.popup-internet-width{
    width: 300px;
    padding: 20px 10px 30px;
    background: #e4e7ea;
}