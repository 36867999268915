$primary-color: #f9b200;
$white: #fff;
$black: #000;
$gray: #333333;
$smoke-white: #e4e7ea;
$light-white: #f2f2f2;
$box-shadow: #0000001a;
$box-shadow-hover: rgba(0, 0, 0, 0.25);
$lite-white: #e1e1e1;
$medium-gray: #A3A4A5;
$smoky-white: #ddd;
$main-gray: #4b4b4b;
$mild-white: #e9e9e9;
$dark-white: #bbb;
$darky-white: #717171;
$dark-blue: #2c303a;
$lighter-gray: #616161;
$err-msg: #ce0000;
$smoke: #b9b9b9;
$main-white : #e8e8e8;
$place-holder: #9e9e9e;
$smoke-gray: #666;
$darken-gray: #535458;
$ivory-white: #C6C6C6;
$powder-white: #d1d1d1;
$skin-white: #EDEDF1;
$label-mandatory: #F55241;
$gray-lite: #696B7B;