@import './themes.scss';
@import './breakpoints.scss';

@mixin flex-component(){
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
@mixin align-items($value-of-align){
    -webkit-box-align: $value-of-align;
    -ms-flex-align: $value-of-align;
    align-items: $value-of-align;
}
@mixin justify-content($value-of-webback,$value-of-justify){
    -webkit-box-pack: $value-of-webback;
    -ms-flex-pack: $value-of-webback;
    justify-content: $value-of-justify;
}
@mixin flex-wrap(){
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
@mixin box-shadow($value-of-boxshadow){
    -webkit-box-shadow: $value-of-boxshadow;
    box-shadow: $value-of-boxshadow;
}
@mixin transition($value-of-transition){
    -webkit-transition: $value-of-transition;
    transition: $value-of-transition;
}
@mixin position-sticky(){
    position: -webkit-sticky;
    position: sticky;
}
@mixin transform($value-of-transition){
    -webkit-transform: $value-of-transition;
    transform: $value-of-transition; 
}
@mixin flex-direction($value-of-orient,$value-of-direction,$value-of-flex-direction){
    -webkit-box-orient: $value-of-orient;
    -webkit-box-direction: $value-of-direction;
    -ms-flex-direction: $value-of-flex-direction;
    flex-direction: $value-of-flex-direction;
}
@mixin animation($value-of-animation){
    animation: $value-of-animation;
}
@mixin filter($value-of-filter){
    -webkit-filter: $value-of-filter;
          filter: $value-of-filter;
}


$baseFontSize: 16px;
@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1rem;
}

html {
  // set base font size (all font-size declarations are rem so they point to this)
  font-size: $baseFontSize;
  -webkit-text-size-adjust: auto;
  -ms-text-size-adjust: $baseFontSize;
}


html {
    @media screen and (min-width: 768px) {
        font-size: 16px;
    }
}