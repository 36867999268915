@import '../../common.scss';

.header, .header-dashboard {
  padding: 30px 20px;
  background-color: $primary-color;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 80px;
  @include for-custom(600px){
    height: 60px;
  }
}
.dashboard-machine{
  .header-text{
    display: block !important;
    width: 50% !important;
    @include for-size(mobile-only){
      width: auto !important;
    }
  }
  .header-logo{
    @include for-size(mobile-only){
      display: none;
    }
  }
  li.logout{    
    margin-top: 0 !important;
    &:before{
      display: none;
    }
  }
}

.header-dashboard {
  padding: 20px;
}
.header-logo-dashboard {
  @include flex-component();
  @include align-items(center);
}
.header{
  @include for-size(mobile-only) {    
    padding: 20px 10px;
  }
  .header-col {
    @include flex-component();
    @include justify-content(justify,space-between);
    @include align-items(center);
    width: 100%;
  }
  .header-logo {
    width: 155px;
    img{
      @include for-size(mobile-only) {    
        display: none;
      }
    }
  }
  .header-text {
    max-width: 60%;
    text-align: center;
    padding: 10px;
    @include for-size(mobile-only) {    
      display: block;
    }
    h1 {
      font-size: rem(16);
      font-weight: 500;
      color: #333;
    }
    .release {
      font-size: rem(15);
      font-weight: 500;
      color: #333;
      margin-top: 10px;
    }
  }
}
.header-design{
  .header-col{
    @include for-size(mobile-only){
      justify-content: center;
    }
  }
  .header-text{
    margin-left: -155px;
    @include for-size(mobile-only){
      margin-left: 0;
      width: 100% !important;
      text-align: center;
    }
  }
  .header-login{
    @include for-size(mobile-only){      
      display: none;
    }
  }
}
.header-design.inner-header{
  .header-text{
    margin-left: 0;
    @include for-size(mobile-only){
      text-align: left;
    }
  }
  .header-login{
    @include for-size(mobile-only){      
      display: block;
    }
  }
  .header-col{
    @include for-size(mobile-only){
      justify-content: space-between;
    }
  }
}
.header-dashboard, .dashboard-machine {
  @include flex-component();
  @include align-items(center);
  .header-logo-img {
    width: 120px;
    @include for-size(mobile-only) {    
      display: none;
    }
  }
  div.menu-nav {
    list-style: none;
    margin-right: 20px;
    cursor: pointer;
    height: 15px;
    @include flex-component();
    @include align-items(center);
    span.menu-nav-items {
      width: 25px;
      height: 2px;
      background: $black;
      display: block;
      position: relative;
      &::before, &::after{
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background: $black;
      }
      &::before{
        top: 7px;
      }
      &::after{
        top: -7px;
      }
    }
  }
  .header-login{
    &:hover ul#login-activity{
      // visibility: visible;
      // opacity: 1;
     // top: 55px;
    }
    .header-profile-details{      
      @include flex-component();
      @include align-items(center);
      cursor: pointer; 
      figure {       
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        img.user-login {
          width: 100%;
          height: 100%;
        }
      }
      .header-profile {
        @include flex-component();
        width: calc(100% - 50px);
        @include align-items(center);
        @include for-size(mobile-only){
          display: none;
        }
        span {
          display: inline-block;
          font-size: rem(14);
          color: $white;
          margin-right: 5px;
          text-transform: capitalize;
        }
        img {
          position: relative;
          top: 1px;
        }
      }
    }
  }
  ul#login-activity {
    list-style: none;
    background: $white;
    position: absolute;
    z-index: 3;
    right: 20px;
    min-width: 185px;
    @include box-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
    margin-top: 10px;
    //visibility: hidden;
    top: 65px;
    @include transition(0.5s ease all);
    //opacity: 0;
    padding: 10px;
    a {
      display: inline-block;
      text-decoration: none;
      color: $black;
      font-size: rem(13);
      width: 100%;
      @include transition(0.3s ease all);
      margin-left: 10px;
      &:hover {
        // background-color: $main-gray;
        // color: $white;
      }
    }
    span {
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      color: $black;
      font-size: rem(13);
      width: 100%;
      @include transition(0.3s ease all);
      margin-left: 10px;
      &:hover {
        // background-color: $main-gray;
        // color: $white;
      }
    }
    &:before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      background: $white;
      top: -6px;
      right: 15px;
      @include transform(rotate(45deg));
    }
    li {
      position: relative;
      @include flex-component();
      @include align-items(center);
      padding: 10px 5px;
      border-radius: 3px;
      @include transition(0.3s ease all);
      svg{
        width: 15px;
        @include transition(0.3s ease all);
      }
      &:hover{
        background-color: $gray-lite;
        a{
          color: $white;
        }
      }
      &.stroke-profile:hover{
          path{
            stroke: $white;
          }
      }
      &.fill-profile:hover{
        path{
          fill: $white;
        }
    }
      padding: 10px;
      &.logout{
        cursor: pointer;
        background-color: #F1EBDB;
        position: relative;
        border-radius: 3px;
        margin-top: 15px;
        @include transition(0.3s ease all);
        span{
          margin-left: 10px;
          color: $primary-color;
          @include transition(0.3s ease all);
          font-size: rem(13);
        }
        &:hover{
          background-color: $primary-color;
          span{
            color: $black;
          }
          svg{
            path{

              stroke: $black;
            }
          }
        }
        &:before{
          content: '';
          position: absolute;
          left: -10px;
          top: -9px;
          width: 185px;
          background: $smoky-white;
          height: 1px;
        }
      }
    }
  }
}
.header-page-content {
  @include flex-component();
  @include align-items(center);
  h3 {
    font-size: rem(18);
    margin-left: 18px;
  }
  img{
    width: 18px;
    cursor: pointer;
  }
}
.header-inner-page{
  display: none;
  @include for-size(mobile-only) {    
    display: flex;  
  }  
}