@import '../../common.scss';

.footer {
  padding: 5px 20px;
  background: $black;
  color: $white;
  text-align: center;
  @include position-sticky();
  top: 100%;
  font-size: 15px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
