@import '../../common.scss';

.page-not-found {
    padding: 30px 15px 50px;
    @include for-custom(600px){
        min-height: calc(100vh - 130px);
    }
    figure {
        max-width: 350px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
    } 
    .content-404 {
        text-align: center;
        margin-top: 35px;
        p {
            margin: 10px 0 35px;
        }    
    }
}  
// .login-btn{
//     img{
//         height: 22px;
//         top: 48%;
//     }
// }

.error-boundary-block{
    border:  3px solid $primary-color;
    margin: auto 20%;
    text-align: center;
    border-radius: 25px;
    background: beige;
}
